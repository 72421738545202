<template>
  <ProductCostsFormDrawer
    ref="ProductCostsFormDrawer"
    :value="form.product_variants[0]"
    @input="handleSave($event)"
  />
</template>

<script>
import ProductCostsFormDrawer from "@/components/product/forms/ProductCostsFormDrawer.vue";

export default {
  components: {
    ProductCostsFormDrawer,
  },

  data: () => ({
    form: {
      status: 1,
      product_variants: [{}],
    },
  }),

  methods: {
    async open(id) {
      await this.showProduct(id);

      this.$refs.ProductCostsFormDrawer.open();
    },

    async showProduct(id) {
      if (id) {
        this.$loading.start();
        await this.$http
          .$get(`product/product/${id}`)
          .then((response) => {
            this.form = response.product;
            this.$loading.finish();
          })
          .catch((error) => {
            this.$loading.finish();
            this.dialog = false;
          });
      }
    },

    handleSave(data) {
      this.form.product_variants[0] = data;

      this.$loading.start();
      this.$http
        .update("product/product", this.form.id, this.getForm())
        .then((response) => {
          this.processSaved();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
    getForm() {
      return {
        name: this.form.name,
        code: this.form.code,
        ncm: this.form.ncm,
        cest: this.form.cest,
        origem: this.form.origem,
        classificacao_fiscal: this.form.classificacao_fiscal,
        observations: this.form.observations,
        status: this.form.status,
        category: this.form.category ? { id: this.form.category.id } : null,
        brand: this.form.brand ? { id: this.form.brand.id } : null,
        supplier: this.form.supplier ? { id: this.form.supplier.id } : null,
        product_variants: this.mapProductVariants(),
      };
    },
    mapProductVariants() {
      return this.form.product_variants.map((variant) => {
        return {
          id: variant.id || null,
          product_id: variant.product_id || null,
          unity: variant.unity ? { id: variant.unity.id } : null,
          code: variant.code,
          webcom_id: variant.webcom_id,
          external_id: variant.external_id,
          manufacturer_id: variant.manufacturer_id,
          weight_gross: variant.weight_gross,
          weight_net: variant.weight_net,
          sell_multiple: variant.sell_multiple,
          purchase_multiple: variant.purchase_multiple,
          purchase_factor: variant.purchase_factor,
          gtin: variant.gtin,
          packaging_gtin: variant.packaging_gtin,
          packaging_quantity: variant.packaging_quantity,
          purchase_price: variant.purchase_price,
          st: variant.st,
          ipi: variant.ipi,
          recomposicao_aliquota: variant.recomposicao_aliquota,
          shipping: variant.shipping,
          shipping_percent: variant.shipping_percent,
          cost: variant.cost,
          icms_debt: variant.icms_debt,
          icms_credit: variant.icms_credit,
          pis: variant.pis,
          cofins: variant.cofins,
          irpj: variant.irpj,
          csll: variant.csll,
          simples_nacional: variant.simples_nacional,
          commission: variant.commission,
          price_cash: variant.price_cash,
          price_forward: variant.price_forward,
          desired_profit: variant.desired_profit,
          desired_price_cash: variant.desired_price_cash,
          profit: variant.profit,
          status: variant.status,

          product_companies: this.mapProductCompanies(variant),
        };
      });
    },

    mapProductCompanies(variant) {
      return variant.product_companies.map((company) => {
        return {
          id: company.id || null,
          product_id: company.product_id || null,
          product_variant_id: company.product_variant_id || null,
          company_id: company.company_id || null,
          cst: company.cst,
          csosn: company.csosn,
          icms_regime: company.icms_regime,
          purchase_price: company.purchase_price,
          st: company.st,
          ipi: company.ipi,
          recomposicao_aliquota: company.recomposicao_aliquota,
          shipping: company.shipping,
          shipping_percent: company.shipping_percent,
          cost: company.cost,
          icms_debt: company.icms_debt,
          icms_credit: company.icms_credit,
          pis: company.pis,
          cofins: company.cofins,
          irpj: company.irpj,
          csll: company.csll,
          simples_nacional: company.simples_nacional,
          commission: company.commission,
          price_cash: company.price_cash,
          price_forward: company.price_forward,
          desired_profit: company.desired_profit,
          desired_price_cash: company.desired_price_cash,
          profit: company.profit,
          can_sell: company.can_sell,
          moves_stock: company.moves_stock,
          minimum_stock: company.minimum_stock,
          maximum_stock: company.maximum_stock,
          status: company.status,
        };
      });
    },
    processSaved() {
      this.$emit("store", this.form);
      this.$loading.finish();
      this.dialog = false;
    },
  },
};
</script>

<style>
</style>

