<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-icon :color="classes[color].color" small v-bind="attrs" v-on="on">mdi-alert-circle</v-icon>
    </template>
    <span>{{ text }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    productCompany: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    classes: {
      stockZero: {
        color: '#BDBDBD',
        textColor: 'text--disabled',
      },

      lowOnStock: {
        color: 'warning',
        textColor: 'warning--text',
      },

    },
  }),

  computed: {
    color() {

      if (this.productCompany.available_stock == 0) {
        return "stockZero";
      }

      if (this.productCompany.available_stock <= this.productCompany.minimum_stock) {
        return "lowOnStock";
      }

    },

    text() {
      if (this.productCompany.available_stock == 0) {
        return "Sem estoque";
      }

      if (this.productCompany.available_stock <= this.productCompany.minimum_stock) {
        return "Estoque baixo";
      }
    },
  }
};
</script>

<style lang="scss" scoped></style>