<template>
  <v-dialog v-model="dialog" width="800px" scrollable persistent>
    <v-card>
      <v-card-title primary-title> Exportar para Excel </v-card-title>
      <v-card-text>
        <br />
        <v-row>
          <v-col md="6">
            <ClassificacaoFiscalSelect v-model="form.classificacao_fiscal" />
          </v-col>
          <v-col>
            <CategoriesSearch v-model="form.category" @input="setCategoryId()" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <BrandsSearch v-model="form.brand" @input="setBrandId($event)" />
          </v-col>
          <v-col>
            <SupplierSearch v-model="form.supplier" @input="setSupplierId($event)" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <app-number-field v-model="form.purchase_id" label="Id Compra" :precision="0" :allowNegative="false"
              :forceZero="false" />
          </v-col>
          <v-col>
            <CompanySelect v-model="form.company" @input="setCompanyId($event)" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" @click="dialog = false" text> Cancelar </v-btn>
        <v-btn color="primary" @click="handleExport()"> Exportar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ClassificacaoFiscalSelect from "@/components/product/ui/ClassificacaoFiscalSelect";
import BrandsSearch from "@/components/brands/ui/BrandsSearch.vue";
import SupplierSearch from "@/components/suppliers/ui/SupplierSearch.vue";
import CategoriesSearch from "@/components/categories/ui/CategoriesSearch.vue";
import CompanySelect from "@/components/company/ui/CompanySelect.vue";

export default {
  components: {
    ClassificacaoFiscalSelect,
    BrandsSearch,
    SupplierSearch,
    CompanySelect,
    CategoriesSearch,
  },

  data() {
    return {
      dialog: false,
      form: {
        columns: [],
      },

      baseForm: {
        status: 1,
        low_stock: false,
        classificacao_fiscal: "00",
        category_id: null,
        brand_id: null,
        supplier_id: null,
        purchase_id: null,
        company_id: null,

        company: {},
        columns: [],
        tableType: null,
      },



      date: new Date().toISOString().slice(0, 10),
    };
  },

  created() {
    this.reset();
  },

  methods: {
    open() {
      this.reset();
      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    setSupplierId(supplier) {
      this.form.supplier_id = supplier.id || null;
    },
    setBrandId(brand) {
      this.form.brand_id = brand.id || null;
    },
    setCategoryId(category) {
      this.form.category_id = category.id || null;
    },

    setCompanyId(company) {
      this.form.company_id = company.id || null;
    },

    setColumns(columns) {
      if (columns != "custom") {
        if (columns) {
          this.form.columns = this.columns.filter((column) =>
            columns.some((c) => c.value == column.value)
          );
        }
        if (!columns) {
          this.form.columns = [];
        }
      }
    },

    handleExport() {
      this.$loading.start();
      this.$http
        .download("product/product-suggestion", this.form)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Produtos_${this.form.company.short_name}_${this.date}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          this.dialog = false;
          this.$loading.finish();
        })
        .catch((error) => {
          console.log(error);
          this.$loading.finish();
        });
    },
  },
};
</script>

<style></style>