<template>
  <v-dialog v-model="dialog" width="800px" scrollable persistent>
    <v-card>
      <v-card-title primary-title> Exportar para Excel </v-card-title>
      <v-card-text>
        <br />
        <v-row>
          <v-col md="6">
            <ClassificacaoFiscalSelect v-model="form.classificacao_fiscal" />
          </v-col>
          <v-col>
            <CategoriesSearch
              v-model="form.category"
              @input="setCategoryId()"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <BrandsSearch v-model="form.brand" @input="setBrandId($event)" />
          </v-col>
          <v-col>
            <SupplierSearch
              v-model="form.supplier"
              @input="setSupplierId($event)"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <app-number-field
              v-model="form.purchase_id"
              label="Id Compra"
              :precision="0"
              :allowNegative="false"
              :forceZero="false"
            />
          </v-col>
          <v-col>
            <CompanySelect
              v-model="form.company"
              @input="setCompanyId($event)"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <app-number-field
              v-model="form.available_stock_more_than"
              :allowNegative="true"
              :forceZero="false"
              label="Estoque maior que"
            />
          </v-col>
          <v-col>
            <app-number-field
              v-model="form.available_stock_less_than"
              :allowNegative="true"
              :forceZero="false"
              label="Estoque menor que"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <app-select
              clearable
              :items="headersABC"
              v-model="form.stock_abc_curve"
              label="Curva ABC Estoque"
            />
          </v-col>
          <v-col>
            <app-select
              clearable
              :items="headersABC"
              v-model="form.sale_abc_curve"
              label="Curva ABC Vendas"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-switch
              v-model="form.low_stock"
              label="Abaixo do estoque mínimo"
            />
          </v-col>
          <v-col>
            <v-chip-group v-model="form.status" column>
              <v-chip filter :value="1" color="primary">Ativo</v-chip>
              <v-chip filter :value="0">Inativo</v-chip>
            </v-chip-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <app-select
              :items="tableOptions"
              @input="setColumns($event)"
              v-model="form.tableType"
              clearable
              label="Tipo de Planilha"
            />
          </v-col>
        </v-row>
        <br />
        <template v-if="form.tableType === 'custom'">
          <h3>Colunas</h3>
          <v-row>
            <v-col>
              <v-chip-group v-model="form.columns" column multiple>
                <v-chip
                  v-for="column of columns"
                  :key="column.value"
                  :value="column"
                  filter
                  small
                >
                  {{ column.text }}
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
        </template>
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" @click="dialog = false" text> Cancelar </v-btn>
        <v-btn color="primary" @click="handleExport()"> Exportar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ClassificacaoFiscalSelect from "@/components/product/ui/ClassificacaoFiscalSelect";
import BrandsSearch from "@/components/brands/ui/BrandsSearch.vue";
import SupplierSearch from "@/components/suppliers/ui/SupplierSearch.vue";
import CategoriesSearch from "@/components/categories/ui/CategoriesSearch.vue";
import CompanySelect from "@/components/company/ui/CompanySelect.vue";

export default {
  components: {
    ClassificacaoFiscalSelect,
    BrandsSearch,
    SupplierSearch,
    CompanySelect,
    CategoriesSearch,
  },

  data() {
    return {
      dialog: false,
      form: {
        columns: [],
      },

      baseForm: {
        status: 1,
        low_stock: false,
        classificacao_fiscal: "00",
        category_id: null,
        brand_id: null,
        supplier_id: null,
        purchase_id: null,
        company_id: null,
        stock_abc_curve: null,
        sale_abc_curve: null,
        available_stock_more_than: null,
        available_stock_less_than: null,

        company: {},
        columns: [],
        tableType: null,
      },

      headersABC: [
        { text: "A", value: "A" },
        { text: "B", value: " B" },
        { text: "C", value: "C" },
      ],

      columns: [
        { value: "code", text: "Código" },
        { value: "name", text: "Nome" },
        { value: "short_name", text: "Nome Curto" },
        { value: "brand", text: "Marca" },
        { value: "brand_code", text: "Código Marca" },
        { value: "supplier", text: "Fornecedor" },
        { value: "supplier_code", text: "Código Fornecedor" },
        { value: "category", text: "Categoria" },
        { value: "category_code", text: "Código Categoria" },
        { value: "ncm", text: "NCM" },
        { value: "cest", text: "CEST" },
        { value: "origem", text: "Origem" },
        { value: "classificacao_fiscal", text: "Classificação Fiscal" },
        { value: "available_stock", text: "Estoque Disponível" },
        { value: "reserved_stock", text: "Estoque Reservado" },
        { value: "expedition_stock", text: "Estoque Expedição" },
        { value: "physic_stock", text: "Estoque Físico" },
        { value: "minimum_stock", text: "Estoque Mínimo" },
        { value: "maximum_stock", text: "Estoque Máximo" },
        { value: "stock_abc_curve", text: "Curva ABC Estoque" },
        { value: "sale_abc_curve", text: "Curva ABC Vendas" },

        { value: "purchase_price", text: "Preço de Compra" },
        { value: "st", text: "ST" },
        { value: "ipi", text: "IPI" },
        { value: "recomposicao_aliquota", text: "Recomposição de Aliquota" },
        { value: "shipping", text: "Frete" },
        { value: "cost", text: "Custo" },
        { value: "icms_debt", text: "ICMS Saídas" },
        { value: "icms_credit", text: "ICMS Entradas" },
        { value: "pis", text: "PIS" },
        { value: "cofins", text: "COFINS" },
        { value: "irpj", text: "IRPJ" },
        { value: "csll", text: "CSLL" },
        { value: "simples_nacional", text: "Simples Nacional" },
        { value: "commission", text: "Comissão" },
        { value: "price_cash", text: "Preço A Vista" },
        { value: "status", text: "Status" },
      ],

      tableOptions: [
        {
          text: "Atualização de Valores",
          value: [
            { value: "code", text: "Código" },
            { value: "name", text: "Nome" },
            { value: "short_name", text: "Nome Curto" },
            { value: "brand", text: "Marca" },
            { value: "purchase_price", text: "Preço de Compra" },
            { value: "st", text: "ST" },
            { value: "ipi", text: "IPI" },
            {
              value: "recomposicao_aliquota",
              text: "Recomposição de Aliquota",
            },
            { value: "shipping", text: "Frete" },
            { value: "cost", text: "Custo" },
            { value: "icms_debt", text: "ICMS Saídas" },
            { value: "icms_credit", text: "ICMS Entradas" },
            { value: "pis", text: "PIS" },
            { value: "cofins", text: "COFINS" },
            { value: "irpj", text: "IRPJ" },
            { value: "csll", text: "CSLL" },
            { value: "simples_nacional", text: "Simples Nacional" },
            { value: "commission", text: "Comissão" },
            { value: "price_cash", text: "Preço A Vista" },
            { value: "stock_abc_curve", text: "Curva ABC Estoque" },
            { value: "sale_abc_curve", text: "Curva ABC Vendas" },
          ],
        },

        {
          text: "Atualização em Massa",
          value: [
            { value: "code", text: "Código" },
            { value: "name", text: "Nome" },
            { value: "short_name", text: "Nome Curto" },
            { value: "brand_code", text: "Marca" },
            { value: "supplier_code", text: "Fornecedor" },
            { value: "category_code", text: "Categoria" },
            { value: "ncm", text: "NCM" },
            { value: "cest", text: "CEST" },
            { value: "origem", text: "Origem" },
            { value: "classificacao_fiscal", text: "Classificação Fiscal" },
            { value: "available_stock", text: "Estoque Disponível" },
            { value: "reserved_stock", text: "Estoque Reservado" },
            { value: "expedition_stock", text: "Estoque Expedição" },
            { value: "physic_stock", text: "Estoque Físico" },
            { value: "minimum_stock", text: "Estoque Mínimo" },
            { value: "maximum_stock", text: "Estoque Máximo" },
            { value: "stock_abc_curve", text: "Curva ABC Estoque" },
            { value: "sale_abc_curve", text: "Curva ABC Vendas" },

            { value: "purchase_price", text: "Preço de Compra" },
            { value: "st", text: "ST" },
            { value: "ipi", text: "IPI" },
            {
              value: "recomposicao_aliquota",
              text: "Recomposição de Aliquota",
            },
            { value: "shipping", text: "Frete" },
            { value: "cost", text: "Custo" },
            { value: "icms_debt", text: "ICMS Saídas" },
            { value: "icms_credit", text: "ICMS Entradas" },
            { value: "pis", text: "PIS" },
            { value: "cofins", text: "COFINS" },
            { value: "irpj", text: "IRPJ" },
            { value: "csll", text: "CSLL" },
            { value: "simples_nacional", text: "Simples Nacional" },
            { value: "commission", text: "Comissão" },
            { value: "price_cash", text: "Preço A Vista" },
            { value: "status", text: "Status" },
          ],
        },
        {
          text: "Relatório Customizado",
          value: "custom",
        },
      ],

      date: new Date().toISOString().slice(0, 10),
    };
  },

  created() {
    this.reset();
  },

  methods: {
    open() {
      this.reset();
      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    setSupplierId(supplier) {
      this.form.supplier_id = supplier.id || null;
    },
    setBrandId(brand) {
      this.form.brand_id = brand.id || null;
    },
    setCategoryId(category) {
      this.form.category_id = category.id || null;
    },

    setCompanyId(company) {
      this.form.company_id = company.id || null;
    },

    // validate() {
    //   if (this.validateCompany()) {
    //     this.handleExport();
    //   }
    // },

    // validateCompany() {
    //   if (this.form.company_id == null) {
    //     this.$snackbar({
    //       message: "Selecione uma empresa",
    //       props: {
    //         color: "error",
    //       },
    //     });

    //     return false;
    //   }

    //   if (this.form.company_id != null) {
    //     return true;
    //   }
    // },

    setColumns(columns) {
      if (columns != "custom") {
        if (columns) {
          this.form.columns = this.columns.filter((column) =>
            columns.some((c) => c.value == column.value)
          );
        }
        if (!columns) {
          this.form.columns = [];
        }
      }
    },

    handleExport() {
      this.$loading.start();
      this.$http
        .download("product/export", this.form)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Produtos_${this.form.company.short_name}_${this.date}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          this.dialog = false;
          this.$loading.finish();
        })
        .catch((error) => {
          console.log(error);
          this.$loading.finish();
        });
    },
  },
};
</script>

<style>
</style>